<template>
  <div>
    <div class="text-center p-t-10">
      <div
        v-for="tag in tagList"
        :key="tag.key"
        class="p-l-20 p-r-20 m-b-2"
        @click="handleChangeTag(tag.key)"
        :class="
          currentTag === tag.key
            ? 'workplatform-radio-button-active'
            : 'workplatform-radio-button'
        "
      >
        {{ tag.name }}
      </div>
    </div>
    <div v-if="allowEdit">
      <Divider dashed><span class="divider-text">{{currentTag==='adx'?'跨运营商报备品牌':'创建'+tagName }}</span></Divider>
      <component :is="currentComponent + 'Component'" />
    </div>
    <div v-else>
      <div class="default-content">
        <p style="font-size: large">{{ tagName }}详细信息请在右侧查看</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    customerComponent: () => import('@/components/advertiser/CreateAdvertiser'),
    firstPartyComponent: () =>
      import('@/components/advertiser/CreateFirstparty'),

    adxComponent: () => import('@/components/advertiser/AdxReport')
  },
  data () {
    return {
      currentTag: 1,
      currentComponent: 'customer',
      tagName: '',
      tagList: []
    }
  },
  created () {
    this.initTags()
  },
  methods: {
    initTags () {
      if (
        this.isAuth('Advertiser_Manage_View') ||
        this.isAuth('Advertiser_Sale_View')
      ) {
        this.tagList.push({ key: 'customer', name: '客户' })
      }
      if (this.isAuth('FirstParty_View')) {
        this.tagList.push({ key: 'firstParty', name: '合同甲方' })
      }
      // if (this.isAuth('FirstParty_View')) {
      //   this.tagList.push({ key: 'adx', name: 'ADX' })
      // }

      this.tagList.push({ key: 'brand', name: '品牌库' })
      this.handleChangeTag(this.tagList[0].key)
    },
    handleChangeTag (tag) {
      this.currentTag = tag
      this.currentComponent = tag
      this.tagName = this.tagList.find((x) => x.key === tag).name
      this.$store.commit('set_advertiser_pageType', this.currentComponent)
      this.$store.commit('set_advertiser_detailId', null)
    }
  },
  computed: {
    allowEdit: function () {
      // let startDate = new Date(this.contract.startDate)
      return (this.currentTag === 'customer' && this.isAuth('Advertiser_Edit')) ||
      (this.currentTag === 'firstParty' && this.isAuth('FirstParty_Edit')) ||
      (this.currentTag === 'adx'
      // && this.isAuth('adx_reporting_report')
      )
    }
  }
}
</script>
